import React from "react"
import LayoutA7 from "../../components/layout-a7"
import EnrollStep from "../../components/enroll-step"
import enrollStyles from "./a7_enroll.module.css"

export default class A7EnrollStep extends React.Component {
  render() {
    const color='#00D4CE'
    const src='/demos/themeA7/enroll'
    return(
      <LayoutA7 >
        <div className={enrollStyles.enrollContainer}>
          <EnrollStep color={color} src={src} />
        </div>
      </LayoutA7>
    )
  }
}